import { SVGProps } from 'react';

const SvgWavingHand = (properties: SVGProps<SVGSVGElement>) => (
  <svg width={23} height={23} fill="none" xmlns="http://www.w3.org/2000/svg" {...properties}>
    <path
      d="M23 16c0 3.31-2.69 6-6 6v-1.5c2.48 0 4.5-2.02 4.5-4.5H23ZM1 6c0-3.31 2.69-6 6-6v1.5C4.52 1.5 2.5 3.52 2.5 6H1Zm7.01-2.68-4.6 4.6c-3.22 3.22-3.22 8.45 0 11.67 3.22 3.22 8.45 3.22 11.67 0l7.07-7.07c.49-.49.49-1.28 0-1.77a1.25 1.25 0 0 0-1.77 0l-4.42 4.42-.71-.71 6.54-6.54c.49-.49.49-1.28 0-1.77a1.25 1.25 0 0 0-1.77 0l-5.83 5.83-.71-.71 6.89-6.89c.49-.49.49-1.28 0-1.77a1.25 1.25 0 0 0-1.77 0L11.71 9.5l-.69-.7 5.48-5.48c.49-.49.49-1.28 0-1.77a1.25 1.25 0 0 0-1.77 0L7.11 9.17a4.003 4.003 0 0 1-.33 5.28l-.71-.71a3 3 0 0 0 0-4.24l-.35-.35 4.07-4.07c.49-.49.49-1.28 0-1.77a1.27 1.27 0 0 0-1.78.01Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgWavingHand;
