import React from "react";
import { Typography, AccordionDetails, Accordion } from "@mui/material";
import { StyledAccordionSummary } from "../../molecules/PropertiesBox/style";
import ReactJson from "react-json-view";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export function ErrorDetails(error: any) {
  return (
    <Accordion
      style={{ margin: "0", padding: "0" }}
      defaultExpanded={false}
      disableGutters={true}
    >
      <StyledAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <Typography>
          <b>Details</b></Typography>
      </StyledAccordionSummary>
      <AccordionDetails>
        <ReactJson
          style={{ margin: "0", padding: "0", pointerEvents: "none" }}
          src={error}
          theme="monokai"
          iconStyle="triangle"
          displayDataTypes={false}
          quotesOnKeys={false}
          enableClipboard={false}
          indentWidth={3}
          onSelect={false}
          displayObjectSize={false}
          shouldCollapse={(field) => false}
          name={false}
        />
      </AccordionDetails>
    </Accordion>
  );
}
