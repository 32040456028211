import React from "react";
import { Styledh1, StyledBanner } from "./style";

export function BannerComponent({
  bannerText,
  className,
  messages,
}: {
  bannerText: string;
  className?: string;
  messages?: string[];
}) {
  return (
    <StyledBanner className={className}>
      <Styledh1>{bannerText}</Styledh1>
      {messages?.map((message, i) => (
        <p key={i}>{message}</p>
      ))}
    </StyledBanner>
  );
}
