import { SVGProps } from 'react';

const SvgAlessaLogo = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1884.000000 292.000000"
    preserveAspectRatio="xMidYMid meet"
    width={130}
    height={42}
    fill="currentColor"
    {...properties}
  >
    <g
      transform="translate(0.000000,292.000000) scale(0.100000,-0.100000)"
      fill="currentColor"
      stroke="none"
    >
      <path
        d="M10810 2859 c-472 -99 -717 -469 -614 -929 46 -204 202 -376 435
-476 46 -19 192 -71 324 -115 273 -90 396 -147 478 -219 144 -129 179 -355 84
-550 -66 -135 -220 -247 -393 -286 -115 -25 -302 -16 -409 20 -139 47 -244
123 -325 235 -24 33 -47 61 -51 61 -3 0 -59 -36 -123 -79 l-117 -79 57 -67
c170 -201 387 -304 708 -336 223 -23 447 24 621 129 79 48 192 162 237 239
104 177 132 396 77 610 -22 88 -55 152 -111 220 -101 121 -265 218 -478 283
-427 129 -527 172 -639 276 -78 72 -113 133 -130 228 -56 304 111 543 424 607
268 55 507 -16 656 -196 19 -23 37 -43 40 -45 9 -7 219 142 219 155 0 5 -39
48 -87 96 -127 124 -252 187 -446 224 -114 22 -318 19 -437 -6z"
      />
      <path
        d="M13990 2869 c-298 -50 -537 -224 -631 -461 -99 -251 -43 -588 128
-764 122 -126 220 -176 600 -304 301 -102 421 -159 498 -236 221 -221 134
-615 -168 -764 -115 -57 -202 -73 -357 -67 -139 5 -241 31 -337 87 -68 39
-164 129 -203 190 -18 28 -36 50 -41 50 -13 0 -239 -152 -239 -160 0 -4 36
-46 81 -93 170 -182 380 -279 672 -308 398 -40 748 123 892 416 54 108 75 207
75 350 0 209 -43 333 -160 458 -121 130 -257 201 -549 287 -290 85 -395 130
-507 214 -127 95 -174 198 -174 381 0 146 45 252 150 348 115 107 246 151 450
151 112 1 147 -3 205 -21 101 -32 188 -88 264 -169 l66 -71 102 74 c56 41 105
78 108 83 3 5 -23 39 -57 76 -169 181 -387 265 -678 263 -74 -1 -160 -5 -190
-10z"
      />
      <path
        d="M1240 2833 c0 -1 105 -248 234 -550 687 -1611 848 -1987 887 -2080
l43 -103 134 0 c104 0 133 3 129 13 -26 68 -1171 2685 -1178 2692 -8 8 -249
35 -249 28z"
      />
      <path
        d="M4070 1460 l0 -1360 758 2 757 3 0 115 0 115 -632 3 -633 2 0 1240 0
1240 -125 0 -125 0 0 -1360z"
      />
      <path
        d="M6997 2813 c-4 -3 -7 -615 -7 -1360 l0 -1353 860 0 860 0 0 120 0
120 -730 0 -730 0 0 525 0 525 655 0 655 0 0 115 0 115 -655 0 -655 0 0 480 0
480 700 0 700 0 0 120 0 120 -823 0 c-453 0 -827 -3 -830 -7z"
      />
      <path
        d="M17420 2801 l-65 -6 -572 -1310 c-315 -721 -581 -1331 -592 -1357
l-20 -48 134 0 133 0 187 438 c103 240 335 785 516 1210 181 425 358 840 394
923 36 82 65 152 65 154 0 6 -92 4 -180 -4z"
      />
      <path
        d="M381 808 c-160 -381 -291 -696 -291 -701 0 -4 64 -6 141 -5 l142 3
183 450 c101 248 227 554 279 682 52 127 95 236 95 241 0 9 -115 22 -205 22
l-52 0 -292 -692z"
      />
      <path
        d="M17973 1473 c-35 -2 -63 -7 -63 -10 0 -3 54 -137 119 -297 65 -160
191 -468 280 -685 88 -216 162 -395 164 -398 2 -2 67 -2 144 -1 l141 3 -293
695 -292 695 -69 1 c-38 0 -97 -1 -131 -3z"
      />
    </g>
  </svg>
);

export default SvgAlessaLogo;
