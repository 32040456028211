import { useEffect, useRef } from 'react';

export function useEventListener(
  eventType: string,
  callback: any,
  element: Window = window
) {
  const callbackReference = useRef(callback);

  useEffect((): void => {
    callbackReference.current = callback;
  }, [callback]);

  useEffect(() => {
    const handler = (event: any) => callbackReference.current(event);

    element.addEventListener(eventType, handler);

    return () => element.removeEventListener(eventType, handler);
  }, [eventType, element]);
}
