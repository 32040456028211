import { SVGProps } from 'react';

const SvgStorage = (properties: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...properties}>
    <path
      d="M0 16h20v-4H0v4Zm2-3h2v2H2v-2ZM0 0v4h20V0H0Zm4 3H2V1h2v2Zm-4 7h20V6H0v4Zm2-3h2v2H2V7Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgStorage;
