import { SVGProps } from 'react';

const SvgRoundedPerson = (properties: SVGProps<SVGSVGElement>) => (
  <svg width={94} height={94} fill="none" xmlns="http://www.w3.org/2000/svg" {...properties}>
    <rect width={94} height={94} rx={47} fill="#959595" />
    <path
      d="M15 66.733c6.7-10.375 18.527-17.266 32-17.266 13.472 0 25.3 6.891 32 17.267C72.3 77.11 60.473 84 47 84c-13.472 0-25.3-6.891-32-17.267Z"
      fill="#fff"
    />
    <ellipse cx={47} cy={26.033} rx={16} ry={16.033} fill="#fff" />
  </svg>
);

export default SvgRoundedPerson;
