import styled from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';
import { AccordionSummary } from '@mui/material';


export const StyledAccordionSummary = styledMui(AccordionSummary)(() => ({
	":hover": {
		background: 'rgba(0, 0, 0, 0.04)',
	}
}));

export const AccordionSummaryStyle = {
	flexWrap: 'wrap',
	display: 'table',
	margin: '0,',
	padding: '0',
};

export const Ul = styled.ul`
  	flex-wrap: wrap;
  	display: table;
	margin: 0;
	padding: 0;
`;

export const Li = styled.ul`
 display: table-cell;
`;
