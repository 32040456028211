import React, { } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Ul, Li, StyledAccordionSummary } from './style';

interface IAccordionProps extends React.TextareaHTMLAttributes<HTMLDivElement> {
  data: Object;
  label: string;
  disabled: boolean;
}

const PropertiesBox = ({ data, label, disabled, ...rest }: IAccordionProps) => {

  if (data === undefined || data === null || Object.keys(data).length === 0)
    return (<div></div>)

  let propList;
  if (data) {
    propList = Object.entries(data ?? {}).map(([key, value]) => {
      let newValue = new Date(value).toLocaleString();
      if (newValue === "Invalid Date")
        newValue = value;
      let label = key.replace(/([^A-Z])([A-Z])/g, '$1 $2');
      label = label.charAt(0).toUpperCase() + label.slice(1);
      return <Li key={key}> <b>{label}</b>: {newValue}</Li>;
    });
  }

  return (
    <div  {...rest}>
      <Accordion
        defaultExpanded={false}
        disableGutters={true}
        disabled={disabled || data === undefined || data === null || Object.keys(data).length === 0}
      >
        <StyledAccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <Typography>
            <b>{label}</b></Typography>
        </StyledAccordionSummary>
        <AccordionDetails>
          <Ul>
            {propList}
          </Ul>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export { PropertiesBox };
