import { SVGProps } from 'react';

const SvgListAlt = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path d="M19 5v14H5V5h14Zm1.1-2H3.9c-.5 0-.9.4-.9.9v16.2c0 .4.4.9.9.9h16.2c.4 0 .9-.5.9-.9V3.9c0-.5-.5-.9-.9-.9ZM11 7h6v2h-6V7Zm0 4h6v2h-6v-2Zm0 4h6v2h-6v-2ZM7 7h2v2H7V7Zm0 4h2v2H7v-2Zm0 4h2v2H7v-2Z" />
  </svg>
);

export default SvgListAlt;
