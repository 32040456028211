import { SVGProps } from 'react';

const SvgCopy = (properties: SVGProps<SVGSVGElement>) => (
  <svg width={19} height={22} fill="none" xmlns="http://www.w3.org/2000/svg" {...properties}>
    <path
      d="M14 0H2C.9 0 0 .9 0 2v14h2V2h12V0Zm3 4H6c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2Zm0 16H6V6h11v14Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgCopy;
