import React from "react";
import { StyledButton } from "./style";

export const enum ButtonTheme {
  Primary = 1,
  Secondary = 2,
  Tertiary = 3,
  Danger = 4
}

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  content: string | React.ReactNode;
  theme: ButtonTheme;
  width?: string;
}

export function Button({ content, width, theme, ...rest }: ButtonProps) {
  const [backgroundColor, setBackgroundColor] = React.useState("");
  const [textColor, setTextColor] = React.useState("");
  const [borderColor, setBorderColor] = React.useState("");
  const [hover, setHover] = React.useState({ backgroundColor: "", color: "" });

  React.useEffect(() => {
    const renderButtonColoring = (theme: ButtonProps["theme"]) => {
      switch (theme.valueOf()) {
        case ButtonTheme.Primary.valueOf():
          return {
            backgroundColor: "var(--accent-600)",
            color: "#fff",
            border: "2px solid var(--accent-600)",
            hover: {
              backgroundColor: "var(--accent-700)",
              color: "#fff",
            },
          };
        case ButtonTheme.Secondary.valueOf():
          return {
            backgroundColor: "#fff",
            color: "var(--accent-700)",
            border: "2px solid var(--accent-600)",
            hover: {
              backgroundColor: "var(--surface-75)",
              color: "var(--accent-700)",
            },
          };
        case ButtonTheme.Tertiary.valueOf():
          return {
            backgroundColor: "#fff",
            color: "var(--main-blue)",
            border: "2px solid var(--main-blue)",
            hover: {
              backgroundColor: "var(--main-blue-hover)",
              color: "#fff",
            },
          };
        case ButtonTheme.Danger.valueOf():
          return {
            backgroundColor: "#fff",
            color: "var(--main-red)",
            border: "2px solid var(--main-red)",
            hover: {
              backgroundColor: "var(--main-red-hover)",
              color: "#fff",
            },
          };
        default:
          return {
            backgroundColor: "var(--main-green)",
            color: "#fff",
            border: "2px solid var(--main-green)",
            hover: {
              backgroundColor: "var(--main-green-hover)",
              color: "#fff",
            },
          };
      }
    };
    const { backgroundColor, color, border, hover } =
      renderButtonColoring(theme);

    setBackgroundColor(backgroundColor);
    setTextColor(color);
    setBorderColor(border);
    setHover(hover);
  }, [theme]);

  return (
    <StyledButton
      color={textColor}
      borderColor={borderColor}
      hover={hover}
      width={width}
      backgroundColor={backgroundColor}
      {...rest}
    >
      {content}
      {/* <input type="file" accept=".json" hidden onChange={() => { }} /> */}

    </StyledButton>
  );
}
