import { MutatingDots } from "react-loader-spinner";

export const LoaderComponent = ({ content = "Loading...", ...rest }) => {
  return (
    <div {...rest}>
      <MutatingDots wrapperStyle={{ display: "block" }} color="#00BFFF" height={100} width={100} />
      <h1>{content}</h1>
    </div>
  );
};
