import { SVGProps } from 'react';

const SvgMinusOutline = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    width={21}
    height={20}
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path d="M5.75 9v2h10V9h-10Zm5-9c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10Zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8Z" />
  </svg>
);

export default SvgMinusOutline;
