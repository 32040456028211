import { SVGProps } from 'react';

const SvgHome = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={17}
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path d="M8 17v-6h4v6h5V9h3L10 0 0 9h3v8h5Z" />
  </svg>
);

export default SvgHome;
