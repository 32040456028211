import { SVGProps } from 'react';

const SvgCheckBoxMinus = (properties: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...properties}>
    <path
      d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2Zm-2 10H7v-2h10v2Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgCheckBoxMinus;
