import { FunctionComponent, ReactElement, SVGProps } from 'react';

import * as Icons from '../icons';

interface Properties extends SVGProps<SVGSVGElement> {
  name: keyof typeof Icons;
  angle?: number;
  scale?: number;
}

export const Icon: FunctionComponent<Properties> = ({
  name,
  angle = 0,
  scale = 1,
  style,
  ...rest
}: Properties): ReactElement<Properties> => {
  // eslint-disable-next-line import/namespace
  const Component = Icons[`${name}`];

  return (
    <Component
      name={name}
      style={{ transform: `rotate(${angle}deg) scale(${scale})`, ...style }}
      data-testid={name}
      {...rest}
    />
  );
};
