import { SVGProps } from 'react';

const SvgFullscreenOff = (properties: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...properties}>
    <path
      fill="currentColor"
      d="M18 7h4v2h-6V3h2v4zM8 9H2V7h4V3h2v6zm10 8v4h-2v-6h6v2h-4zM8 15v6H6v-4H2v-2h6z"
    />
  </svg>
);

export default SvgFullscreenOff;
