import styled from "styled-components";
import ProfilePic from "../../../assets/profile.jpg";

export const StyledNavbar = styled.nav`
  background-color: #000;
  color: #fff;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 1;
  height: 60px;
  padding: 1rem;
  box-sizing: border-box;
`;

export const ProfilePicDiv = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-image: url(${ProfilePic});
  background-size: cover;
  background-position: center;
  border: 2px solid #cde6ed;
  margin-left: auto;
  cursor: pointer;
  position: relative;
`;

export const UserMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  border: 2px solid #cde6ed;
  top: 60px;
  width: 200px;
  height: auto;
  background-color: #fff;
  border-radius: 5px;
  color: #000;
  animation: dropdownTopRight 0.4s ease-in-out;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 1rem;
`;

export const Tooltip = styled.div`
  position: absolute;
  top: -15px;
  right: 0;
  background: none;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #cde6ed;
`;

export const CameraBox = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 25px;
  right: -10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  cursor: pointer;
`;

export const NavRightSideDiv = styled.div`
  margin: 0 1rem 0 auto;
  display: flex;
  align-items: center;
  width: 20%;
  position: relative;
`;
