import { SVGProps } from 'react';

const SvgGreyFile = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    width={25}
    height={29}
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.232 8.732 15.451.951A3.232 3.232 0 0 0 13.163 0h-9.94C1.45 0 .015 1.45.015 3.222L0 25.778C0 27.55 1.434 29 3.206 29h17.738a3.232 3.232 0 0 0 3.223-3.222V11.004a3.22 3.22 0 0 0-.935-2.272Zm-1.482 2.546H14.5a1.616 1.616 0 0 1-1.611-1.611v-7.25l8.861 8.86Z"
    />
  </svg>
);

export default SvgGreyFile;
