import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import { IPublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAzureAuthenticationConfig } from "./azure/azure-authentication-config";
import Ajv from "ajv";

import { useNetwork } from "./network";
import { useUserStore } from "./zustand";
import { useEventListener } from "./hooks";
import { Navbar, Login, TenantAdministration } from "./components";

const App = () => {
  const { user, setUser } = useUserStore((state) => state);
  const { loginRequest, instance } = useAzureAuthenticationConfig();
  const { setBearerToken, geCurrentToken } = useNetwork();

  const ajv = new Ajv({
    allErrors: true,
    verbose: true,
    strictDefaults: false,
  });

  const PrivateRoute = ({
    component: Component,
  }: {
    component: JSX.Element;
  }) => {
    return user.isLoggedIn ? Component : <Navigate to="/login" replace />;
  };

  async function handleLogin(instance: IPublicClientApplication) {
    try {
      const loginAttempt = await instance.loginPopup(loginRequest);
      setBearerToken(loginAttempt.accessToken);
      setUser({
        username: loginAttempt.account?.name ?? "",
        isLoggedIn: true,
        email: loginAttempt.account?.username ?? "",
      });
    } catch (error) {
      console.error(error);
    }
  }

  async function handleMicrosoftLogout(
    instance: IPublicClientApplication,
    logoutCallback: () => void
  ) {
    try {
      await instance.logoutPopup();
      setBearerToken("");
      logoutCallback();
    } catch (error) {
      console.error(error);
    }
  }

  function storeCurrentToken() {
    const currentToken = geCurrentToken();
    if (currentToken) {
      sessionStorage.setItem("token", currentToken);
    }
  }

  function retrieveCurrentToken() {
    const token = sessionStorage.getItem("token");
    if (token) {
      setBearerToken(token);
      sessionStorage.removeItem("token");
    }
  }

  useEventListener("unload", storeCurrentToken);
  useEventListener("load", retrieveCurrentToken);

  return (
    <MsalProvider instance={instance}>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        closeOnClick
        pauseOnHover
      />
      <div className="App" style={{ width: "100vw", height: "auto" }}>
        <Navbar handleMicrosoftLogout={handleMicrosoftLogout} />
        <Routes>
          <Route
            path="/:id?"
            element={<PrivateRoute component={<TenantAdministration ajv={ajv} />} />}
          />
          <Route path="/login" element={<Login handleLogin={handleLogin} />} />
        </Routes>
      </div>
    </MsalProvider>
  );
};

export default App;
