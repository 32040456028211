import validator from "@rjsf/validator-ajv8";
import { Form as JsonSchemaForm } from "@rjsf/mui";
import { IChangeEvent } from "@rjsf/core";
import { ErrorBoundary } from 'react-error-boundary'
import { FormMode } from "./FormMode";
import { CreateHooks } from "./FormHooks";
import { StyledJsonFormContainer } from "./style";

interface IJsonFormProps extends React.ButtonHTMLAttributes<HTMLDivElement> {
  mode: FormMode;
  schema: any;
  data: any;
  errorMessage?: string;
  handleSubmit: () => void | undefined;
  handleUpdate: (data: any) => void;
}

export const JsonForm = (
  {
    mode,
    schema,
    data,
    errorMessage,
    handleUpdate,
    handleSubmit,
    ...rest
  }: IJsonFormProps
) => {

  const ErrorFallback = (error: any): JSX.Element => {
    return (
      <div role="alert">
        <p>Something went wrong:</p>
        <pre>{error.error.message}</pre>
        <button onClick={error.resetErrorBoundary}>Try again</button>
      </div>
    )
  };

  const contentContainer = (content?: any) => {
    return (
      <StyledJsonFormContainer>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => { window.location.reload(); }}>
          {content}
        </ErrorBoundary>
      </StyledJsonFormContainer>
    )
  };

  if (errorMessage && errorMessage !== "") {
    return (contentContainer(<div>{errorMessage}</div>));
  }

  //if create mode then all fields should be editable
  const setReadOnlyValues = (schemaIn: any) =>
    Object.entries(schemaIn).forEach((x: any) => {
      if (x[1] !== undefined) {
        if (x[1]["ui:readonly"] !== undefined) {
          if (mode === FormMode.Create) x[1]["ui:readonly"] = false;
          if (mode === FormMode.Update) x[1]["ui:readonly"] = true;
        }
        else if (typeof x[1] === 'object') {
          setReadOnlyValues(x[1])
        }
      }
    });

  let uiSchema = schema?.uiSchema;
  if (!uiSchema) {
    uiSchema = {
      "ui:submitButtonOptions": {
        "norender": true
      }
    }
  }
  else {
    setReadOnlyValues(uiSchema)
  }

  var hooks = CreateHooks(schema?.hooks);
  hooks.onRenderDataHook(data)

  const handleFormDataChanged = (changed: IChangeEvent, e?: string) => {
    hooks.onFormChangeHook(changed.formData, e)
    delete changed.formData.subscription.customerLimit;
    delete changed.formData.subscription.transactionLimit;
    return handleUpdate(changed.formData)
  };

  return (
    <div {...rest} data-testid="json-form">
      {contentContainer(
        (
          <JsonSchemaForm
            liveValidate={mode === FormMode.Create || mode === FormMode.Update}
            disabled={mode === FormMode.Read}
            schema={schema}
            uiSchema={uiSchema}

            formData={data}
            validator={validator}
            onChange={(changed: any, e: string | undefined) => handleFormDataChanged(changed, e)}
            showErrorList={false}
          />
        ))}
    </div>
  );
};
