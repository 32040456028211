import { SVGProps } from 'react';

const SvgSchedule = (properties: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...properties}>
    <path
      d="M9.99 0C4.47 0 0 4.48 0 10s4.47 10 9.99 10C15.52 20 20 15.52 20 10S15.52 0 9.99 0ZM10 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8Z"
      fill="currentColor"
    />
    <path d="M10.5 5H9v6l5.25 3.15.75-1.23-4.5-2.67V5Z" fill="currentColor" />
  </svg>
);

export default SvgSchedule;
