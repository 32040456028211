import {
  HTMLAttributes,
  ForwardRefExoticComponent,
  CSSProperties,
  forwardRef,
  ForwardedRef,
  ReactElement,
  RefAttributes,
} from 'react';
import styled from 'styled-components';

export type ContainerProperties = HTMLAttributes<HTMLDivElement> &
  RefAttributes<HTMLDivElement> & {
    readonly margin?: string;
    readonly padding?: string;
    readonly border?: string;
    readonly radius?: string;
    readonly width?: string;
    readonly height?: string;
    readonly gap?: string;
    readonly overflow?: {
      x?: CSSProperties['overflow'];
      y?: CSSProperties['overflow'];
    };
    readonly vertical?: CSSProperties['alignItems'];
    readonly horizontal?: CSSProperties['justifyContent'];
    readonly direction?: CSSProperties['flexDirection'];
    readonly background?: string;
    readonly zIndex?: number;
  };

const StyledContainer = styled.div`
  display: flex;
  position: relative;
  margin: ${(properties: ContainerProperties) => properties.margin ?? 0};
  padding: ${(properties: ContainerProperties) => properties.padding ?? 0};
  border: ${(properties: ContainerProperties) => properties.border ?? 0};
  border-radius: ${(properties: ContainerProperties) => properties.radius ?? 0};
  width: ${(properties: ContainerProperties) => properties.width ?? '100%'};
  height: ${(properties: ContainerProperties) => properties.height ?? 'auto'};
  overflow-y: ${(properties: ContainerProperties) => properties.overflow?.y ?? 'inherit'};
  overflow-x: ${(properties: ContainerProperties) => properties.overflow?.x ?? 'inherit'};
  align-items: ${(properties: ContainerProperties) => properties.vertical ?? 'inherit'};
  justify-content: ${(properties: ContainerProperties) => properties.horizontal ?? 'inherit'};
  flex-direction: ${(properties: ContainerProperties) => properties.direction ?? 'inherit'};
  background: ${(properties: ContainerProperties) => properties.background ?? 'transparent'};
  gap: ${(properties: ContainerProperties) => properties.gap ?? '0px'};
  z-index: ${(properties: ContainerProperties) => properties.zIndex ?? 'unset'};
`;

export const Container: ForwardRefExoticComponent<ContainerProperties> = forwardRef<
  HTMLDivElement,
  ContainerProperties
>(function Container(
  properties: ContainerProperties,
  reference: ForwardedRef<HTMLDivElement>,
): ReactElement {
  const { children, ...rest } = properties;

  return (
    <StyledContainer ref={reference} {...rest}>
      {children}
    </StyledContainer>
  );
});
