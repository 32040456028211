import { SVGProps } from 'react';

const SvgCursor = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path d="m3.738 5.05 6.14 14.68c.35.83 1.53.81 1.85-.02l2.07-5.34a1 1 0 0 1 .57-.57l5.33-2.06c.84-.32.86-1.51.03-1.86L5.058 3.73c-.83-.33-1.66.5-1.32 1.32Z" />
  </svg>
);

export default SvgCursor;
