import { SVGProps } from 'react';

const SvgFirstPage = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={24}
    width={24}
    fill="currentColor"
    {...properties}
  >
    <path d="M18.41 16.59 13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z" />
    <path d="M24 24H0V0h24v24z" fill="none" />
  </svg>
);

export default SvgFirstPage;
