import { SVGProps } from 'react';

const SvgAlert = (properties: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={25} fill="none" xmlns="http://www.w3.org/2000/svg" {...properties}>
    <path
      d="M23.626 6.643 17.382.4c-.253-.253-.6-.4-.947-.4H7.577c-.347 0-.693.147-.934.387L.387 6.643c-.24.24-.387.587-.387.934v8.845c0 .36.147.694.387.947l6.243 6.243c.254.254.6.4.947.4h8.845c.36 0 .694-.146.947-.386l6.243-6.244a1.32 1.32 0 0 0 .387-.947V7.577a1.278 1.278 0 0 0-.373-.934Z"
      fill="#C70C0C"
    />
    <path
      d="m21.344 15.875-5.47 5.47H8.138l-5.469-5.47V8.138l5.47-5.47h7.737l5.47 5.47v7.737Z"
      fill="#991D1D"
    />
    <path
      d="M12.006 16.008a1.334 1.334 0 1 0 0 2.668 1.334 1.334 0 0 0 0-2.668ZM12.006 5.336c-.734 0-1.334.6-1.334 1.334v6.67c0 .734.6 1.334 1.334 1.334.734 0 1.334-.6 1.334-1.334V6.67c0-.734-.6-1.334-1.334-1.334Z"
      fill="#fff"
    />
  </svg>
);

export default SvgAlert;
