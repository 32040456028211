import { SVGProps } from 'react';

const SvgErrorSmall = (properties: SVGProps<SVGSVGElement>) => {
  const { fill } = properties;
  return (
    <svg width={18} height={18} xmlns="http://www.w3.org/2000/svg" {...properties} fill={fill}>
      <path
        d="M12.73 0H5.27L0 5.27v7.46L5.27 18h7.46L18 12.73V5.27L12.73 0ZM14 12.74 12.74 14 9 10.26 5.26 14 4 12.74 7.74 9 4 5.26 5.26 4 9 7.74 12.74 4 14 5.26 10.26 9 14 12.74Z"
        fill={fill ?? 'currentColor'}
      />
    </svg>
  );
};

export default SvgErrorSmall;
