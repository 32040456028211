import { SVGProps } from 'react';

const SvgAbstractUser = (properties: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={600} height={600} fill="#fff" {...properties}>
    <defs>
      <clipPath id="abstractUser_svg__b">
        <circle cx={300} cy={300} r={280} />
      </clipPath>
      <clipPath id="abstractUser_svg__a">
        <rect width="100%" height={498} />
      </clipPath>
    </defs>
    <circle cx={300} cy={300} r={280} fill="currentColor" clipPath="url(#abstractUser_svg__a)" />
    <circle cx={300} cy={230} r={115} />
    <circle cx={300} cy={550} r={205} clipPath="url(#abstractUser_svg__b)" />
  </svg>
);

export default SvgAbstractUser;
