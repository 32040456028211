import {
  ReactElement,
  SetStateAction,
  Dispatch,
  HTMLAttributes,
  useCallback,
  useState,
  ReactNode,
  KeyboardEvent,
  MouseEvent,
} from "react";
import FocusTrap from "focus-trap-react";
import Select, {
  AriaGuidanceProps,
  AriaOnChangeProps,
  AriaOnFocusProps,
  ClearIndicatorProps,
  components,
  DropdownIndicatorProps,
  GroupBase,
  MenuPlacement,
  MenuPosition,
  OptionProps,
  OptionsOrGroups,
} from "react-select";
import styled from "styled-components";

import { Icon, Container } from "..";
import { useKeyPressHandler } from "../../../hooks";

export interface Option {
  value: string;
  label:
  | string
  | ReactNode
  | JSX.Element
  | ReactElement
  | ((properties?: any) => ReactNode)
  | ((properies?: any) => ReactElement)
  | ((properies?: any) => JSX.Element);
}

interface Properties extends HTMLAttributes<HTMLElement> {
  options: Option[];
  selected?: Option | null;
  setSelected?: Dispatch<SetStateAction<Option | null>>;
  selectedOptions?: Option[] | undefined[];
  setSelectedOptions?: Dispatch<SetStateAction<Option[]>>;
  isMultiple?: boolean;
  colorTheme?: "light" | "dark";
  margin?: string;
  required?: boolean;
  showError?: boolean;
  showSuccess?: boolean;
  disabled?: boolean;
  defaultDropdownIndicator?: boolean;
  showDropdownIndicator?: boolean;
  inputLabel?: string;
  bottomLabel?: string;
  menuPlacement?: MenuPlacement;
  menuPosition?: MenuPosition;
  aria_label?: string;
  listPadding?: number;
  outlined?: boolean;
  menuWidth?: string;
  width?: string;
  isClearable?: boolean;
  onClearValue?: (event?: any) => void;
}

interface WrapperProperties {
  margin: string;
  error: boolean;
  success: boolean;
  disabled: boolean;
}

const labelColor = (
  error = false,
  success = false,
  disabled = false,
  hovered = false,
  focused = false
) => {
  if (disabled) {
    return "var(--surface-500)";
  }
  if (success) {
    return `var(--green-${!hovered ? "600" : "700"})`;
  }
  if (error) {
    return `var(--red-${!hovered ? "600" : "700"})`;
  }
  return !focused
    ? `var(--surface-${!hovered ? "700" : "800"})`
    : "var(--accent-600)";
};

const Wrapper = styled.div<WrapperProperties>`
  width: fit-content;
  z-index: 10;
  margin: ${({ margin }) => margin};
  position: relative;

  .select-box {
    padding: 0.1rem;
    border-radius: 4px;
    box-shadow: 0 0 0 2px var(--surface-100);
  }

  .state-icon {
    display: ${({ success, error }) => (success || error ? "block" : "none")};
    position: absolute;
    top: 18px;
    right: 10px;

    svg {
      margin-right: 0.5rem;
    }
  }

  label,
  span,
  .state-icon > svg {
    color: ${({ error, success, disabled }) =>
    labelColor(error, success, disabled)};
  }

  :hover {
    label,
    .state-icon > svg {
      color: ${({ error, success, disabled }) =>
    labelColor(error, success, disabled, true)};
    }
  }

  :focus-within {
    .select-box {
      outline: 2px solid var(--accent-400);
      outline-offset: 2px;
    }

    label {
      color: ${({ error, success, disabled }) =>
    labelColor(error, success, disabled, false, true)};
    }
  }
`;

const StyledFeedback = styled.span`
  ${({ theme }) => theme.labelSmall}
`;

const StyledLabel = styled.label`
  ${({ theme }) => theme.labelSmall}
  position: absolute;
  top: 6px;
  left: 20px;
  transition: 0.2s ease-in-out;
  pointer-events: none;
`;

const backgroundThemeColor = (colorTheme: "light" | "dark") =>
  colorTheme === "light" ? "white" : "var(--surface-75)";

// const optionHoverColor = (colorTheme: 'light' | 'dark') =>
//   colorTheme === 'light' ? 'var(--surface-75)' : 'white';

const StyledOption = styled.div<{
  isSelected: boolean;
  isMultiple: boolean;
  colorTheme: "light" | "dark";
  listPadding: number;
}>`
  display: flex;
  color: var(--surface-800);
  position: relative;
  align-items: center;
  background-color: ${({ colorTheme }) => backgroundThemeColor(colorTheme)};
  padding: ${(p) => p.listPadding}px;
  font-family: Roboto, sans-serif;
  min-width: 90%;
  height: 1rem;
  cursor: pointer;
  border-radius: 4px;

  .tick {
    opacity: ${({ isSelected }) => (isSelected ? "1" : "0")};
    display: block;
    color: var(--accent-700);
    margin-right: 0.5rem;
  }

  :hover {
    background-color: var(--accent-600);
    color: #fff;

    .tick {
      color: #fff;
    }
  }
`;

/**
  // const [selected, setSelected] = useState<Option | null>(null);
  // const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
 *
 */

const DropdownIndicator = (properties: DropdownIndicatorProps<any>) => {
  return (
    <components.DropdownIndicator {...properties}>
      <Icon name="ArrowDropDown" />
    </components.DropdownIndicator>
  );
};

const ClearIndicator = (onClick?: () => void) => {
  return function Indicator(properties: ClearIndicatorProps<any>) {
    return (
      <components.ClearIndicator {...properties}>
        <Icon name="Cancel" onClick={onClick} />
      </components.ClearIndicator>
    );
  };
};

export default function SelectComponent({
  options,
  menuPlacement = "bottom",
  menuPosition = "absolute",
  isMultiple = false,
  colorTheme = "light",
  selected,
  setSelected,
  selectedOptions,
  setSelectedOptions,
  margin = "0.5rem",
  required = false,
  showError = false,
  showSuccess = false,
  disabled = false,
  defaultDropdownIndicator = false,
  showDropdownIndicator = true,
  bottomLabel,
  inputLabel,
  aria_label,
  listPadding = 20,
  outlined = false,
  menuWidth = "100%",
  width = "275px",
  isClearable = false,
  onClearValue,
  ...rest
}: Properties): ReactElement<Properties> {
  const allOption = { value: "All", label: "Select All" };
  const { onEnterOrSpacePressHandler } = useKeyPressHandler();

  const [isFocused, setIsFocused] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const handleSelectChange = useCallback(
    (selectedOption: any): void => {
      if (selectedOption) setSelected?.(selectedOption);
      setIsOpen(false);
    },
    [setSelected]
  );

  const handleMultiSelectChange = useCallback(
    (selectOptions: any) => {
      if (
        selectOptions.some(
          (option: Option | undefined): boolean => option?.value === "All"
        )
      ) {
        setSelectedOptions?.(options);
      } else {
        setSelectedOptions?.(selectOptions);
      }
    },
    [options, setSelectedOptions]
  );

  const determineDropdownIndicator = useCallback(() => {
    if (showDropdownIndicator) {
      if (defaultDropdownIndicator) {
        return {};
      }
      return { DropdownIndicator };
    }
    return { DropdownIndicator: () => null };
  }, [showDropdownIndicator, defaultDropdownIndicator]);

  const onChangeHandler = isMultiple
    ? handleMultiSelectChange
    : handleSelectChange;

  const borderColor = useCallback(
    (focused: boolean) => {
      if (disabled) {
        return "var(--surface-500)";
      }
      if (showError) {
        return "var(--red-600)";
      }
      if (showSuccess) {
        return "var(--green-600)";
      }
      if (focused) {
        return "var(--accent-600)";
      }
      return "var(--surface-700)";
    },
    [disabled, showError, showSuccess]
  );

  const hoverColor = useCallback(
    (focused: boolean) => {
      if (disabled) {
        return "var(--surface-500)";
      }
      if (showError) {
        return "var(--red-700)";
      }
      if (showSuccess) {
        return "var(--green-700)";
      }
      if (focused) {
        return "var(--accent-700)";
      }
      return "var(--surface-800)";
    },
    [disabled, showError, showSuccess]
  );

  const screenReaderStatus = useCallback(
    ({ count }: { count: number }) => `${count} available options}`,
    []
  );

  const onChange = useCallback(
    (properties: AriaOnChangeProps<any, boolean>) => {
      const {
        action,
        label = "",
        labels,
        isDisabled,
        options: selectOptions,
      } = properties;
      const initialFocusOptions = selectOptions?.map(
        (option: Option) => option.value
      );

      switch (action) {
        case "deselect-option":
        case "pop-value":
        case "remove-value":
          return `option ${label}, deselected}.`;
        case "clear":
          return "All selected options have been cleared.";
        case "initial-input-focus":
          return labels.length > 0
            ? `option ${labels.length > 1 ? "s" : ""}
            ${initialFocusOptions?.join(",") as string}, selected.`
            : "";
        case "select-option":
          return isDisabled
            ? `option ${label} disabled. Select another option.`
            : `option ${label}, selected.`;
        default:
          return "";
      }
    },
    []
  );

  const onFocus = useCallback(
    (properties: AriaOnFocusProps<any, GroupBase<Option>>) => {
      const {
        context,
        focused,
        options: OPTIONS,
        selectValue,
        isDisabled,
      } = properties;

      const selectedValues = selectValue?.map((option: Option) => option.value);

      const getArrayIndex = (
        array: OptionsOrGroups<Option, GroupBase<Option>>,
        item: Option
      ) =>
        array && array.length > 0
          ? `${array.indexOf(item) + 1} of ${array.length}`
          : "";

      const getFocusedOptionValue = (): string => {
        if (focused && focused.value) {
          return focused.value;
        }
        return "";
      };

      if (context === "value" && selectValue) {
        return `value ${getFocusedOptionValue()} focused, ${getArrayIndex(
          selectValue,
          focused
        )}.`;
      }

      if (context === "menu") {
        const disabledSelect = isDisabled ? disabled : "";
        const status = `selected${disabledSelect}`;
        if (selectValue.length > 0) {
          return `option ${selectedValues.join(
            ", "
          )} ${status}, ${getArrayIndex(OPTIONS, focused)}.`;
        }
        return "";
      }
      return "";
    },
    []
  );

  const guidance = useCallback((properties: AriaGuidanceProps) => {
    const { isSearchable, isMulti, isDisabled, tabSelectsValue, context } =
      properties;
    switch (context) {
      case "menu":
        return `Use left and right to choose options ${isDisabled ? "" : "press Enter to select the currently focused option"
          }, press Escape to exit the menu ${tabSelectsValue
            ? "press Tab to select the option and exit the menu"
            : ""
          }.`;
      case "input":
        return `${properties["aria-label"] || "Select"} is focused ${isSearchable ? "type to refine list" : ""
          }, press Enter to open the menu, ${isMulti ? "press left to focus selected values" : ""
          }`;
      case "value":
        return "Use left and right to toggle between focused values, press Backspace to remove the currently focused value";
      default:
        return "";
    }
  }, []);

  const CustomOption = useCallback(
    ({ innerProps, isDisabled, label, isSelected, data }: OptionProps<any>) => {
      const customProperties = { ...innerProps, tabIndex: 0 };
      const { value } = data;

      const handleEnterOrSpacePress = (_event: KeyboardEvent<HTMLElement>) =>
        innerProps.onClick?.(_event as any);

      return !isDisabled ? (
        <div
          onKeyDown={(event) =>
            onEnterOrSpacePressHandler(event, handleEnterOrSpacePress)
          }
          {...customProperties}
          role="option"
          tabIndex={0}
          style={{ margin: "0.5rem" }}
          aria-selected={isSelected}
        >
          <StyledOption
            isSelected={isSelected}
            listPadding={listPadding}
            isMultiple={isMultiple}
            colorTheme={colorTheme}
            aria-label={Number.isNaN(Number(label)) ? value : `${label}`}
          >
            <Icon className="tick" name="Check" />
            {label}
          </StyledOption>
        </div>
      ) : null;
    },
    [isMultiple, listPadding, onEnterOrSpacePressHandler, colorTheme]
  );

  const customStyles: any = {
    control: (
      provided: Record<string, string>,
      { isFocused: _isFocused }: { isFocused: boolean }
    ) => ({
      ...provided,
      width,
      backgroundColor: backgroundThemeColor(colorTheme),
      height: "auto",
      minHeight: "3rem",
      paddingTop: "0.6rem",
      paddingLeft: required ? 0 : 4,
      position: "relative",
      fontFamily: "Roboto",
      color: "var(--surface-800)",
      fontSize: 12,
      border: outlined
        ? `2px solid ${_isFocused ? "transparent" : hoverColor(_isFocused)}`
        : 0,
      cursor: "pointer",
      borderRadius: _isFocused || outlined ? "4px" : "4px 4px 0 0",
      boxShadow: "none !important",
      borderBottom: `2px solid ${_isFocused ? "transparent" : borderColor(_isFocused)
        } `,
      borderLeft: `${required ? "4px" : outlined ? "2px" : "0"
        } solid ${borderColor(_isFocused)}`,
      ":hover": {
        border: outlined
          ? `2px solid ${_isFocused ? "transparent" : hoverColor(_isFocused)}`
          : 0,
        borderBottom: `2px solid ${_isFocused ? "transparent" : hoverColor(_isFocused)
          }`,
        borderLeft: `${required ? "4px" : outlined ? "2px" : "0"
          } solid ${hoverColor(_isFocused)}`,
      },
    }),
    indicatorSeparator: (provided: Record<string, string>) => ({
      ...provided,
      display: "none",
    }),
    dropdownIndicator: (provided: Record<string, string>) => ({
      ...provided,
      position: "absolute",
      top: outlined ? "14%" : "18%",
      right: showError || showSuccess ? "18%" : isMultiple ? "6%" : "2%",
      color: disabled ? "var(--surface-500)" : "var(--surface-800)",
    }),
    menuList: (base: Record<string, string>) => ({
      ...base,
      "::-webkit-scrollbar": {
        width: "6px",
        height: "0",
      },
      "::-webkit-scrollbar-track": {
        background: "none",
        marginTop: "25px",
        marginBottom: "25px",
      },
      "::-webkit-scrollbar-thumb": {
        background: "var(--surface-800)",
        borderRadius: "0.3rem",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    }),
    menu: (provided: Record<string, string>) => ({
      ...provided,
      padding: "0.5rem",
      backgroundColor: backgroundThemeColor(colorTheme),
      minWidth: "100%",
      width: menuWidth,
    }),
    multiValue: (provided: Record<string, string>) => ({
      ...provided,
      backgroundColor: "var(--accent-600)",
      color: "white",
    }),
    multiValueLabel: (provided: Record<string, string>) => ({
      ...provided,
      color: "white",
    }),
    multiValueRemove: (provided: Record<string, string>) => ({
      ...provided,
      ":hover": {
        backgroundColor: "var(--red-600)",
      },
    }),
    clearIndicator: (provided: Record<string, string>) => ({
      ...provided,
      color: "var(--surface-800)",
      ":hover": {
        color: "var(--red-600)",
      },
      marginRight: "2rem",
    }),
    placeholder: (provided: Record<string, string>) => ({
      ...provided,
      color: disabled ? "var(--surface-500)" : "var(--surface-800)",
      marginTop: "0.5rem",
      fontWeight: 400,
      lineHeight: "16px",
      fontSize: "12px",
    }),
  };

  return (
    <Wrapper
      data-testid="select"
      margin={margin}
      error={showError}
      success={showSuccess}
      disabled={disabled}
    >
      <FocusTrap
        active={isOpen}
        focusTrapOptions={{
          onDeactivate: () => setIsOpen(false),
          clickOutsideDeactivates: true,
        }}
      >
        <div
          className="select-box"
          role="combobox"
          aria-expanded={isOpen}
          aria-controls="-menu"
          {...rest}
          onClick={(event: MouseEvent) => {
            event.stopPropagation();
            if (disabled) return;
            setIsOpen(!isOpen);
          }}
          onKeyDown={(event) =>
            onEnterOrSpacePressHandler(
              event,
              () => isFocused && setIsOpen(!isOpen)
            )
          }
          tabIndex={-1}
        >
          <Select
            menuPlacement={menuPlacement}
            menuPosition={menuPosition}
            styles={customStyles}
            options={isMultiple ? [allOption, ...options] : options}
            components={{
              ...determineDropdownIndicator(),
              Option: CustomOption,
              ClearIndicator: ClearIndicator(onClearValue),
            }}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyDown={(event) =>
              event.key === "Enter" && event.preventDefault()
            }
            defaultValue={isMultiple ? selectedOptions : selected}
            value={isMultiple ? selectedOptions : selected}
            onChange={onChangeHandler}
            isMulti={isMultiple}
            placeholder={rest.placeholder}
            hideSelectedOptions={false}
            isSearchable
            ariaLiveMessages={{
              guidance,
              onFocus,
              onChange,
            }}
            screenReaderStatus={screenReaderStatus}
            menuIsOpen={isOpen}
            aria-label={rest["aria-label"]}
            tabSelectsValue={false}
            id={rest.id}
            isDisabled={disabled}
            isClearable={isClearable}
          />
        </div>
      </FocusTrap>

      <StyledLabel htmlFor={rest.id}>{inputLabel}</StyledLabel>
      <div className="state-icon">
        <Icon name={showError ? "ErrorOutline" : "CheckFilled"} />
      </div>
      <Container
        width="100%"
        margin="0.3rem 0 0 0.5rem"
        direction="column"
        horizontal="space-between"
      >
        {bottomLabel && <StyledFeedback>{bottomLabel}</StyledFeedback>}
      </Container>
    </Wrapper>
  );
}
