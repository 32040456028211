import { SVGProps } from 'react';

const SvgListFilter = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path d="M10 18h4v-2h-4v2ZM3 6v2h18V6H3Zm3 7h12v-2H6v2Z" />
  </svg>
);

export default SvgListFilter;
