import { SVGProps } from 'react';

const SvgBell = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={32}
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path d="M16 27.667a2.34 2.34 0 0 0 2.333-2.334h-4.666A2.333 2.333 0 0 0 16 27.667Zm7-7v-5.834c0-3.581-1.913-6.58-5.25-7.373v-.793c0-.969-.782-1.75-1.75-1.75s-1.75.781-1.75 1.75v.793C10.902 8.253 9 11.24 9 14.833v5.834L6.667 23v1.167h18.666V23L23 20.667Z" />
  </svg>
);

export default SvgBell;
