import { SVGProps } from 'react';

const SvgDescription = (properties: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...properties}>
    <path
      d="M10 0H2C.9 0 .01.9.01 2L0 18c0 1.1.89 2 1.99 2H14c1.1 0 2-.9 2-2V6l-6-6Zm2 16H4v-2h8v2Zm0-4H4v-2h8v2ZM9 7V1.5L14.5 7H9Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgDescription;
