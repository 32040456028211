import { SVGProps } from 'react';

const SvgAccountTree = (properties: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...properties}>
    <path
      d="M20 8V0h-7v3H7V0H0v8h7V5h2v10h4v3h7v-8h-7v3h-2V5h2v3h7ZM5 6H2V2h3v4Zm10 6h3v4h-3v-4Zm0-10h3v4h-3V2Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgAccountTree;
