import { SVGProps } from 'react';

const SvgSecurity = (properties: SVGProps<SVGSVGElement>) => (
  <svg width={18} height={22} fill="none" xmlns="http://www.w3.org/2000/svg" {...properties}>
    <path
      d="M9 0 0 4v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V4L9 0Zm0 10.99h7c-.53 4.12-3.28 7.79-7 8.94V11H2V5.3l7-3.11v8.8Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgSecurity;
