import { SVGProps } from 'react';

const SvgActive = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path d="m13.548 16.45 5.215-7.418C19.365 8.181 18.75 7 17.713 7H7.282c-1.038 0-1.64 1.17-1.051 2.031l5.215 7.42a1.279 1.279 0 0 0 2.1 0Z" />
  </svg>
);

export default SvgActive;
