import { SVGProps } from 'react';

const SvgNullMinus = (properties: SVGProps<SVGSVGElement>) => (
  <svg width={22} height={22} xmlns="http://www.w3.org/2000/svg" {...properties}>
    <path
      d="M1 11C1 5.48 5.48 1 11 1s10 4.48 10 10-4.48 10-10 10S1 16.52 1 11Z"
      fill="currentColor"
      stroke="#fff"
      strokeWidth={2}
    />
    <path
      d="M14.667 11.917H7.334A.92.92 0 0 1 6.417 11a.92.92 0 0 1 .917-.917h7.333a.92.92 0 0 1 .917.917.92.92 0 0 1-.917.917Z"
      fill="#fff"
    />
  </svg>
);

export default SvgNullMinus;
