import { SVGProps } from 'react';

const SvgClear = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path d="M20.563 3.413a1.36 1.36 0 0 0-1.925 0l-6.676 6.662L5.288 3.4a1.36 1.36 0 0 0-1.925 0 1.36 1.36 0 0 0 0 1.925L10.037 12l-6.675 6.676a1.36 1.36 0 0 0 0 1.925 1.36 1.36 0 0 0 1.925 0l6.676-6.676 6.675 6.676a1.36 1.36 0 0 0 1.925 0 1.36 1.36 0 0 0 0-1.925L13.887 12l6.676-6.676a1.368 1.368 0 0 0 0-1.911Z" />
  </svg>
);

export default SvgClear;
