import FocusTrap from "focus-trap-react";
import { Button, ButtonTheme, Overlay } from "../../atoms";
import { ModalOption } from "./ModalOption";
import { StyledSuccessMessageContainer, CloseButton } from "./style";

export interface IModalProps {
  content?: any;
  onClick?: (option: ModalOption, onConfirm: () => void) => void;
  options: { option: ModalOption; command: () => void }[];
}

const Modal = ({
  content,
  options,
  onClick = () => {},
  ...rest
}: IModalProps) => {
  const getButton = (option: ModalOption, float: any, theme: ButtonTheme) => {
    const command = options.find((x) => x.option === option)?.command;
    return (
      <Button
        content={ModalOption[option]}
        style={{ float: float, margin: "0.5rem" }}
        onClick={() => {
          if (command) command();
        }}
        theme={theme}
      />
    );
  };
  const modalOptions = options?.map((x) => x.option);

  return (
    <Overlay {...rest}>
      <FocusTrap>
        <StyledSuccessMessageContainer
          style={{ height: "fit-content", width: "fit-content" }}
        >
          {content}
          <div style={{ width: "120%", minWidth: "400px" }}>
            {modalOptions?.includes(ModalOption.Download.valueOf()) &&
              getButton(ModalOption.Download, "left", ButtonTheme.Primary)}
            {modalOptions?.includes(ModalOption.Cancel.valueOf()) &&
              getButton(ModalOption.Cancel, "left", ButtonTheme.Danger)}
            {modalOptions?.includes(ModalOption.No.valueOf()) &&
              getButton(ModalOption.No, "left", ButtonTheme.Danger)}
            {modalOptions?.includes(ModalOption.Continue.valueOf()) &&
              getButton(ModalOption.Continue, "right", ButtonTheme.Primary)}
            {modalOptions?.includes(ModalOption.Yes.valueOf()) &&
              getButton(ModalOption.Yes, "right", ButtonTheme.Primary)}
            {modalOptions?.includes(ModalOption.Ok.valueOf()) &&
              getButton(ModalOption.Ok, "right", ButtonTheme.Primary)}
          </div>
        </StyledSuccessMessageContainer>
      </FocusTrap>
    </Overlay>
  );
};

export { Modal };
