import React from "react";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";

import { BannerComponent, Button, ButtonTheme, PageContainer } from "../../atoms";
import { useUserStore } from "../../../zustand";

export function Login({
  handleLogin,
}: {
  handleLogin: (instance: IPublicClientApplication) => void;
}) {
  const navigate = useNavigate();

  const { isLoggedIn } = useUserStore((state) => state.user);

  const { instance } = useMsal();

  React.useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn, navigate]);

  return (
    <PageContainer>
      <BannerComponent bannerText="Admin Sign In" />
      <Button
        data-testid="login-button"
        theme={ButtonTheme.Primary}
        style={{ margin: "5rem auto" }}
        content="log in with Microsoft"
        onClick={() => handleLogin(instance)}
      />
    </PageContainer>
  );
}
