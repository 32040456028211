import { SVGProps } from 'react';

const SvgRule = (properties: SVGProps<SVGSVGElement>) => (
  <svg width={21} height={17} fill="none" xmlns="http://www.w3.org/2000/svg" {...properties}>
    <path
      d="M14.54 8 11 4.46l1.41-1.41 2.12 2.12L18.77.93l1.41 1.41L14.54 8ZM9 4H0v2h9V4Zm10 6.41L17.59 9 15 11.59 12.41 9 11 10.41 13.59 13 11 15.59 12.41 17 15 14.41 17.59 17 19 15.59 16.41 13 19 10.41ZM9 12H0v2h9v-2Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgRule;
