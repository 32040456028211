import styled from "styled-components";

export const StyledBanner = styled.div`
  position: relative;
  margin: 1rem auto;
  height: 50px;
  width: 90%;
  background: var(--primary-blue);
  border-radius: 0.2rem;
  box-sizing: border-box;
  padding: 12px 8px;
`;

export const Styledh1 = styled.h1`
  font-family: "Proxima Nova Extra Condensed", sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0;
  color: white;
`;
