import { SVGProps } from 'react';

const SvgMemory = (properties: SVGProps<SVGSVGElement>) => (
  <svg width={18} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...properties}>
    <path
      d="M12 6H6v6h6V6Zm-2 4H8V8h2v2Zm8-2V6h-2V4c0-1.1-.9-2-2-2h-2V0h-2v2H8V0H6v2H4c-1.1 0-2 .9-2 2v2H0v2h2v2H0v2h2v2c0 1.1.9 2 2 2h2v2h2v-2h2v2h2v-2h2c1.1 0 2-.9 2-2v-2h2v-2h-2V8h2Zm-4 6H4V4h10v10Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgMemory;
