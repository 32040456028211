import React from "react";
import { StyledLoaderContainer, StyledLoaderOverlay } from "./style";

export function Overlay({ children }: { children: React.ReactNode }) {
  return (
    <StyledLoaderOverlay>
      <StyledLoaderContainer>{children}</StyledLoaderContainer>
    </StyledLoaderOverlay>
  );
}
