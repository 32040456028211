import styled from "styled-components";

export const StyledLoaderOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

export const StyledLoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  box-size: border-box;
  padding: 5rem;
  align-items: center;
  background-color: #fff;
  border-radius: 0.5rem;
  z-index: 1001;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
`;
