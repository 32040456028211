import { SVGProps } from 'react';

const SvgSchema = (properties: SVGProps<SVGSVGElement>) => (
  <svg width={17} height={22} fill="none" xmlns="http://www.w3.org/2000/svg" {...properties}>
    <path
      d="M10 8v2H7V8H4.5V6H7V0H0v6h2.5v2H0v6h2.5v2H0v6h7v-6H4.5v-2H7v-2h3v2h7V8h-7ZM2 2h3v2H2V2Zm3 18H2v-2h3v2Zm0-8H2v-2h3v2Zm10 0h-3v-2h3v2Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgSchema;
