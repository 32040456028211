import { SVGProps } from 'react';

const SvgNumbers = (properties: SVGProps<SVGSVGElement>) => (
  <svg width={18} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...properties}>
    <path
      d="m17.5 6 .5-2h-4l1-4h-2l-1 4H8l1-4H7L6 4H2l-.5 2h4l-1 4h-4L0 12h4l-1 4h2l1-4h4l-1 4h2l1-4h4l.5-2h-4l1-4h4Zm-7 4h-4l1-4h4l-1 4Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgNumbers;
