import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useMutation } from "react-query";

import { useNetwork } from "../../../network";
import {
  BannerComponent,
  Button,
  ButtonTheme,
  LoaderComponent,
  PageContainer
} from "../../atoms";
import { JsonForm, DropdownSearch, Modal, IModalProps, IOption } from "../../molecules";
import Ajv from "ajv";
import { PropertiesBox } from "../../molecules/PropertiesBox";
import { FormMode } from "../../molecules/JsonForm/FormMode";
import { StyledDataButtonsContainer } from "./style";
import { ModalOption } from "../../molecules/Modal/ModalOption";

import _ from "lodash";
import { ErrorDetails } from '../../atoms/ErrorDetails/ErrorDetails';

export const TenantAdministration = ({ ajv }: { ajv: Ajv.Ajv }) => {

  const pathTenantName = window.location.pathname.split("/")[1];

  const { fetchTenantManifest, fetchAllTenants, deleteTenant, fetchTenantSchema, createTenant, updateTenant } = useNetwork();

  const [mode, setMode] = useState<FormMode>(FormMode.Read);

  const [tenantList, setTenantList] = useState<any>([]);
  const [page, setPage] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [formData, setFormData] = useState<any>({});
  const [originalFormData, setOriginalFormData] = useState<any>({});
  const [schema, setTenantSchema] = useState<any>({});

  const [isLoading, setIsLoading] = useState(true);
  const [formErrorMessage, setFormErrorMessage] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);

  const [selectedTenant, setSelectedTenant] = useState<IOption | null>({ name: pathTenantName, id: "", label: "", tid: "" });
  const [modalDetails, setModalDetails] = useState<IModalProps | null>(null);

  const getManifestDownloadRef = useCallback(() => {
    let fileName = "manifest.json";
    if (formData.tenantName) fileName = `${formData.tenantName}-${fileName}`
    const hrefLink = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(formData, null, 2))}`
    return <h3>Click <a download={fileName} href={hrefLink}>here</a> to download manifest.</h3>
    }, [formData])

  const { mutate: createTenantMutation, isLoading: isSigningUp, status: other } = useMutation(
    createTenant,
    {
    onSuccess: async (res: any) => {
      setModalDetails({
        content: <h3>Tenant Created Successfully. {getManifestDownloadRef()}</h3>,
        options: [
          { option: ModalOption.Ok, command: () => window.location.href = `/${res.data.name}` }
          ]
      });
    },
    onError: (err: any) => {
      setModalDetails({
        content: (
          <div>
            <h3>Error Creating Tenant. {getManifestDownloadRef()}</h3>
            <div style={{ width: "100%", margin: "0.5rem auto" }}>
              {err !== null && <ErrorDetails error={{ code: err.code, message: err.message, response: err.response?.data?.data }}></ErrorDetails>}
          </div>
        </div>),
        options: [
          { option: ModalOption.Ok, command: () => setModalDetails(null) }
          ]
      });
      setIsLoading(false);
      setMode(FormMode.Create)
    },
  }
  );

  const { mutate: updateTenantMutation } = useMutation((variables: any) =>
      updateTenant(variables.body, variables.region, variables.orgId), {
      onSuccess: async (res: any) => {
        setModalDetails({
          content: <h3>Tenant Updated Successfully. {getManifestDownloadRef()}</h3>,
          options: [
            { option: ModalOption.Ok, command: () => window.location.href = `/${selectedTenant?.name}` }
        ]
        });
      },
      onError: (err: any) => {
        setModalDetails({
          content: (
            <div>
              <h3>Error Updating Tenant. {getManifestDownloadRef()}</h3>
              <div style={{ width: "100%", margin: "0.5rem auto" }}>
                {err !== null && <ErrorDetails error={{ code: err.code, message: err.message, response: err.response?.data?.data }}></ErrorDetails>}
            </div>
          </div>),
          options: [
            { option: ModalOption.Ok, command: () => setModalDetails(null) }
        ]
        });
        setIsLoading(false);
        setMode(FormMode.Update)
      },
    }
  );

  const handlePaginationChanged = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    handleFetchAllTenantsRequest(undefined, value - 1);
  };

  const handleFetchAllTenantsRequest = async (
    searchTerm?: string,
    pageNo?: number
  ) => {
    try {
      const result = await fetchAllTenants(searchTerm, pageNo);
      if (result !== undefined) {
        setTenantList(result.data.data);
        setPage(result.data.start / result.data.limit);
        setTotal(Math.ceil(result.data.total / result.data.limit));
      } else throw new Error("Tenant list was null.");
    } catch (e) {
      setMode(FormMode.Error);
    }
  };

  const handleDeleteTenantRequest = async (tenantName: string) => await deleteTenant(tenantName);

  useEffect(() => {
    if (mode === FormMode.Error) {
      setFormErrorMessage("Error fetching tenant schema. Please contact support.");
    }
  }, [mode]);

  useEffect(() => {
    const handleFetchSchemaRequest = async () => {
      try {
        const result = await fetchTenantSchema();
        if (result && result.data)
          setTenantSchema(result.data);
        else {
          throw new Error("Tenant schema template was null");
        }
      }
      catch (e) {
        setMode(FormMode.Error);
      }
    }
    handleFetchSchemaRequest();
    handleFetchAllTenantsRequest();
  }, []);

  const handleSelectedTenantChanged = useCallback((tenant: IOption | null) => {
    if (tenant?.name) {
      setFormData({})
      setOriginalFormData({})
      setSelectedTenant(tenant);
      setFormErrorMessage("")
      setIsLoading(true);
      setMode(FormMode.Read);
    }
    else {
      setFormData({})
      setOriginalFormData({})
      setSelectedTenant(null);
      window.history.replaceState({}, "", `/`);
    }
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      if (!selectedTenant?.name) {
        setIsLoading(false);
        setMode(FormMode.Read)
        return;
      };

      const result = await fetchTenantManifest(selectedTenant?.name, undefined).catch((err: any) => console.log(err));

      if (result?.data) {
        setOriginalFormData(result.data);
        setFormData(result.data);
        window.history.replaceState({}, "", `/${selectedTenant?.name}`);
      }
      else {
        setFormData({})
        setOriginalFormData({});
        window.history.replaceState({}, "", `/`);
        setFormErrorMessage("Error fetching tenant manifest. Please contact support.")
      }
      setIsLoading(false);
      setMode(FormMode.Read)
    }
    fetchData();
  
  }, [selectedTenant, tenantList])

  const handleUpdateFormData = (data: any) => {
    setFormData(data);
    setIsFormValid(() => (!(_.isEqual(data, originalFormData)) && ajv.validate(schema, data) === true))
  }

  const handleCreateNewTenant = useCallback(() => {
    setMode(FormMode.Create);
    setFormErrorMessage("")
    setFormData({});
    setOriginalFormData({});
    window.history.replaceState({}, "", `/`);
  }, [setMode, setFormErrorMessage, setFormData]);


  const handleEditTenant = () => {
    setMode(FormMode.Update);
    setFormErrorMessage("");
  };

  const handleUploadManifest = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = JSON.parse(reader.result as string);
        if (!ajv.validate(schema, data)) {
          setModalDetails({
            content: <h3>Invalid tenant manifest selected. Please review file contents and retry.</h3>,
            options: [
              { option: ModalOption.Ok, command: () => setModalDetails(null) }
            ]
          });
        }
        else {
          setFormData(data);
        }
      };
      if (file) {
        reader.readAsText(file);
      }
    },
    [ajv, schema]
  );

  const triggerFileUpload = useCallback(() => (document.getElementById("tenant-manifest-upload"))?.click(), []);

  const handleDiscardChanges = (confirmed: boolean | undefined = false) => {
    if (!confirmed) {
      setModalDetails({
        content: <h3>Do you wish to discard your changes?</h3>,
        options: [
          { option: ModalOption.Yes, command: () => handleDiscardChanges(true) },
          { option: ModalOption.Cancel, command: () => setModalDetails(null) }
        ],
      });
      return;
    }

    if (mode === FormMode.Update) {
      setModalDetails({
        content: <LoaderComponent content="Loading..." />,
        options: []
      });
      window.location.href = `/${selectedTenant?.name}`
    }
    else {
      setMode(FormMode.Read)
      handleSelectedTenantChanged(null);
      setModalDetails(null);
    }
  };

  const handleDeleteTenant = useCallback(async (confirmed: boolean | undefined = false, tenant: IOption | null) => {
      if (!confirmed) {
        setModalDetails({
          content: <h3>Are you sure you wish to delete tenant {tenant?.name}</h3>,
          options: [
            { option: ModalOption.Yes, command: () => handleDeleteTenant(true, tenant) },
            { option: ModalOption.Cancel, command: () => setModalDetails(null) }
          ],
        });
        return;
      }
      setModalDetails({
        content: <LoaderComponent content="Deleting..." />,
        options: []
      });

      setMode(FormMode.Read);
      await handleDeleteTenantRequest(tenant?.name as string);

      setModalDetails({
        content: <h3>{tenant?.name} deleted</h3>,
        options: [
          { option: ModalOption.Ok, command: () => window.location.href = "/" },
        ],
      });
    }, []);

  const handleCreateTenant = (confirmed: boolean = false) => {
    if (!confirmed) {
      setModalDetails({
        content: <h3>Do you wish to create the tenant {formData["name"]}?</h3>,
        options: [
          { option: ModalOption.Continue, command: () => handleCreateTenant(true) },
          { option: ModalOption.Cancel, command: () => setModalDetails(null) }
        ]
      });
      return;
    }
    setModalDetails({
      content: <LoaderComponent content="Creating..." />,
      options: []
    });
    createTenantMutation(formData)
  };

  const handleUpdateTenant = (confirmed: boolean = false) => {
    if (!confirmed) {
      setModalDetails({
        content: <h3>Do you wish to update the tenant {formData["name"]}?</h3>,
        options: [
          { option: ModalOption.Continue, command: () => handleUpdateTenant(true) },
          { option: ModalOption.Cancel, command: () => setModalDetails(null) }
        ]
      });
      return;
    }
    setModalDetails({
      content: <LoaderComponent content="Updating..." />,
      options: []
    });
    updateTenantMutation({ body: formData, region: formData.region, orgId: selectedTenant?.name });
  };

  return (
    <PageContainer>
      <BannerComponent bannerText="Tenant Administration" />
      {modalDetails !== null && (<Modal
        content={modalDetails.content}
        options={modalDetails.options}
      />)}
      <StyledDataButtonsContainer >
        {(mode === FormMode.Read || mode === FormMode.Update) && !isLoading &&
          <div>
            <Button
              content="Create New Tenant"
              onClick={handleCreateNewTenant}
              theme={ButtonTheme.Primary}
              style={{ float: "left" }}
              hidden={mode === FormMode.Update}
              disabled={isLoading}
            />
            <DropdownSearch
              style={{ float: "right" }}
              data={tenantList}
              onSelectFunction={handleSelectedTenantChanged}
              placeholder="Tenants"
              selectedValue={selectedTenant}
              disabled={isLoading || mode === FormMode.Update}
              totalPages={total}
              currentPage={page}
              handlePageChange={handlePaginationChanged}
            ></DropdownSearch>
            <Button
              content={mode === FormMode.Update ? "Editing" : "Edit"}
              onClick={handleEditTenant}
              hidden={selectedTenant === undefined || selectedTenant?.name === ""}
              theme={ButtonTheme.Primary}
              style={{ float: "right", margin: "1rem", width: "fit-content" }}
              disabled={isLoading || mode === FormMode.Update || formErrorMessage !== ""}
            />
            {/* <Button
              content="Delete Tenant"
              onClick={() => handleDeleteTenant(false, selectedTenant)}
              theme={ButtonTheme.Danger}
              style={{ float: "right", margin: "1rem" }}
              disabled={isLoading || formErrorMessage !== ""}
            /> */}
          </div>}
        {mode === FormMode.Create &&
          <div>
            <Button
              style={{ float: "left" }}
              content="Upload Tenant Manifest"
              onClick={triggerFileUpload}
              theme={ButtonTheme.Primary}
            />
            <input id="tenant-manifest-upload"
              type="file"
              accept=".json"
              onChange={handleUploadManifest}
              hidden />
          </div>
        }
      </StyledDataButtonsContainer>
      {isLoading && <LoaderComponent />}
      {!isLoading &&
        <div>
          <PropertiesBox
            data={formData?.metadata}
            style={{ width: "80%", margin: "0.5rem auto" }}
            disabled={mode === FormMode.Create}
            label="Properties"
          />
          <JsonForm
            style={{ width: "80%", maxHeight: "600px", margin: "0 auto", overflow: "auto", overflowX: "hidden" }}
            mode={mode}
            schema={schema}
            data={formData}
            errorMessage={formErrorMessage}
            handleUpdate={handleUpdateFormData}
            handleSubmit={handleCreateTenant}
          />
        </div>
      }
      {(mode === FormMode.Create || mode === FormMode.Update) &&
        <StyledDataButtonsContainer>
          <Button
            style={{ float: "left" }}
            content="Clear form"
            onClick={() => setFormData({})}
            theme={ButtonTheme.Primary}
            hidden={mode === FormMode.Update}
            disabled={isLoading}
          />
          <div style={{ float: "right" }}>
            <Button
              data-testid="tenant-sign-up-button"
              content={mode === FormMode.Update ? "Update Tenant" : "Create Tenant"}
              disabled={!isFormValid}
              onClick={() => mode === FormMode.Update ? handleUpdateTenant() : handleCreateTenant()}
              theme={ButtonTheme.Primary}
            />
            <Button
              content="Discard Changes"
              onClick={() => handleDiscardChanges(false)}
              theme={ButtonTheme.Danger}
              style={{ float: "left", margin: "1rem" }}
              disabled={isLoading}
            />
          </div>
        </StyledDataButtonsContainer>}
    </PageContainer >
  );
};
