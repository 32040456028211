import { SVGProps } from 'react';

const SvgDoNotDisturbOffOutlined = (properties: SVGProps<SVGSVGElement>) => (
  <svg width={21} height={21} fill="none" xmlns="http://www.w3.org/2000/svg" {...properties}>
    <path
      d="M11 2c4.41 0 8 3.59 8 8 0 1.41-.37 2.73-1.01 3.88l1.46 1.46A9.96 9.96 0 0 0 21 10c0-5.52-4.48-10-10-10a9.9 9.9 0 0 0-5.33 1.55l1.46 1.46A7.883 7.883 0 0 1 11 2Zm5 7h-2.88l2 2H16V9ZM1.41.13 0 1.54l2.78 2.78A9.92 9.92 0 0 0 1 10c0 5.52 4.48 10 10 10 2.11 0 4.07-.66 5.68-1.78L19.46 21l1.41-1.41L1.41.13ZM11 18c-4.41 0-8-3.59-8-8 0-1.56.45-3 1.23-4.23L7.46 9H6v2h3.46l5.77 5.77A7.846 7.846 0 0 1 11 18Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgDoNotDisturbOffOutlined;
