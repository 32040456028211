import { KeyboardEvent } from 'react';

function onEnterOrSpacePressHandler(
  event: KeyboardEvent<HTMLElement>,
  callback: (_event: KeyboardEvent<HTMLElement>) => void,
) {
  if (!['Enter', ' '].includes(event.key)) return;
  event.stopPropagation();
  callback(event);
}

function onTabPressHandler(event: KeyboardEvent, callback: (event_: KeyboardEvent) => void) {
  if (!['Tab'].includes(event.key)) return;
  event.stopPropagation();
  callback(event);
}

export const useKeyPressHandler = () => {
  return {
    onEnterOrSpacePressHandler,
    onTabPressHandler,
  };
};
