import axios from 'axios';
import { useEnvSwitcher } from './contexts';
import { useLocalStorage } from './helpers/useLocalStorage';

const adminApi = axios.create();

export const useNetwork = () => {
  const { env } = useEnvSwitcher();
  const [adminApiToken, setAdminApiToken] = useLocalStorage("adminApiToken", "");

  const BASE_URL =
    window.__RUNTIME_CONFIG__?.[
      `REACT_APP_API_ADMIN_${env}` as keyof typeof window.__RUNTIME_CONFIG__
    ]; // window.__RUNTIME_CONFIG__ undefined in testing

  const setBearerToken = (token: string, setCache: boolean = true) => {
    if (setCache) setAdminApiToken(token)
    adminApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    adminApi.defaults.headers.common['X-MS-TOKEN-AAD-ID-TOKEN'] = token;
  };

  const setBearerTokenFromCache = () => setBearerToken(adminApiToken, false);

  const geCurrentToken = () => {
    return adminApi.defaults.headers.common['Authorization']?.toString()?.split(' ')[1];
  };

  const fetchTenantSchema = (): any => {
    return new Promise(async (resolve, reject) => {
      try {
        setBearerTokenFromCache();
        const response = await adminApi.get(`${BASE_URL}/api/tenants/template`);
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  const fetchTenantManifest = (tenantName?: string, searchTerm?: string): any => {
    return new Promise(async (resolve, reject) => {
      try {
        setBearerTokenFromCache();

        let url = `${BASE_URL}/api/tenantsByName/US`;
        if (tenantName) url = url.concat(`/${tenantName}`);

        const response = await adminApi.get(url);
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  const fetchAllTenants = (searchTerm?: string, page?: number): any => {
    return new Promise(async (resolve, reject) => {
      try {
        setBearerTokenFromCache();

        let url = `${BASE_URL}/api/tenants/US`;
        let params: Map<string, string> = new Map();
        if (searchTerm && page) {
          url = url.concat(`?search=${searchTerm}&page=${page}`);
        } else {
          if (searchTerm) url = url.concat(`?search=${searchTerm}`);
          if (page) url = url.concat(`?page=${page}`);
        }

        const response = await adminApi.get(url);
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  const createTenant = (body: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        setBearerTokenFromCache();
        const response = await adminApi.post(`${BASE_URL}/api/tenants`, body);
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  const updateTenant = (body: any, region: string, orgId: string) => {
    return new Promise(async (resolve, reject) => {
      try {
        setBearerTokenFromCache();
        const response = await adminApi.put(`${BASE_URL}/api/tenants/${region}/${orgId}`, body);
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  const deleteTenant = (tenantName: string) => {
    return new Promise(async (resolve, reject) => {
      try {
        setBearerTokenFromCache();

        const body = { name: tenantName, region: "US", token: "" };

        const config = {
          url: `${BASE_URL}/api/tenants`,
          method: 'DELETE',
          data: body,
        }

        let response = await adminApi.request(config);

        if (response.status === 202) {
          body.token = response?.data?.data?.token
          response = await adminApi.request(config);
        }

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  return { fetchTenantManifest, fetchAllTenants, fetchTenantSchema, createTenant, updateTenant, deleteTenant, setBearerToken, geCurrentToken };
};
