import { SVGProps } from 'react';

const SvgSocialScience = (properties: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...properties}>
    <path
      d="M2 2c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2-2-.9-2-2Zm4.78 3.58a6.95 6.95 0 0 0-5.56 0A2.01 2.01 0 0 0 0 7.43V8h8v-.57c0-.81-.48-1.53-1.22-1.85ZM16 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2Zm2.78 1.58a6.95 6.95 0 0 0-5.56 0A2.01 2.01 0 0 0 12 7.43V8h8v-.57c0-.81-.48-1.53-1.22-1.85ZM20 14l-4-4v3H4v-3l-4 4 4 4v-3h12v3l4-4Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgSocialScience;
