import styled from "styled-components";

export const StyledJsonFormContainer = styled.div`
  margin: auto;
  padding: 2rem;
  width: 90%;
  background-color: #e6eef2;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px dashed #c5d7e0;
  color: #355464;
`;
