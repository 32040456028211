import { SVGProps } from 'react';

const SvgCheck = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path d="M9 16.17 5.53 12.7a.996.996 0 1 0-1.41 1.41l4.18 4.18c.39.39 1.02.39 1.41 0L20.29 7.71a.996.996 0 1 0-1.41-1.41L9 16.17Z" />
  </svg>
);

export default SvgCheck;
