/* eslint-disable no-new-func */

interface IFormHooks {
    onFormChangeHook: Function;
    onRenderDataHook: Function;
}

export const CreateHooks = (hooks: any): IFormHooks => {

    let onFormChangeHook: Function = () => { };
    let onRenderDataHook: Function = () => { };

    if (hooks?.onChange) onFormChangeHook = new Function("formData", "target", atob(hooks.onChange))
    if (hooks?.onRenderData) onRenderDataHook = new Function("data", atob(hooks.onRenderData))

    return { onFormChangeHook, onRenderDataHook };
};
