import React from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Box, Pagination } from "@mui/material";

export interface IOption {
  id: string;
  label: string;
  name: string;
  tid: string;
}

interface IDropdownSearchProps extends React.SelectHTMLAttributes<HTMLDivElement> {
  data: any;
  placeholder: string;
  disabled: boolean;
  totalPages?: number | undefined;
  currentPage?: number | undefined;
  selectedValue?: IOption | null;
  handlePageChange?: any;
  onSelectFunction: (value: IOption) => void;
}

const DropdownSearch = ({
  data,
  placeholder,
  disabled,
  selectedValue,
  onSelectFunction,
  currentPage,
  totalPages,
  handlePageChange,
  ...rest
}: IDropdownSearchProps) => {
  const mappedData = data?.map(
    (x: any): IOption => ({
      id: x.id,
      label: x.display_name,
      name: x.name,
      tid: x.metadata?.tid
    })) as IOption[];

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: IOption) => option.label + option.tid
  });

  return (
    <div {...rest}>
      <Autocomplete
        disabled={disabled}
        options={mappedData}
        defaultValue={selectedValue}
        getOptionLabel={(option: any) => option.name}
        filterOptions={filterOptions}
        renderOption={(props, option) => (
            <Box component="li" {...props}>
              <div>
                <b>{option.name}</b>
                <div>({option.tid})</div>
              </div>
            </Box>
        )}
        ListboxComponent={(listboxProps) => (
          <ul {...listboxProps}>
            {listboxProps.children}
            <Pagination
              count={totalPages ?? 0}
              page={(currentPage ?? 0) + 1}
              onChange={handlePageChange}
            />
          </ul>
        )}
        sx={{ width: 300 }}
        freeSolo={true}
        clearOnEscape
        onChange={(_, newValue: any) => {
          onSelectFunction(newValue);
        }}
        renderInput={(params: any) => <TextField {...params} label={placeholder} />
        } />
    </div >
  );
};

export { DropdownSearch };
