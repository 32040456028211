import { SVGProps } from 'react';

const SvgKeyboardDoubleArrowDown = (properties: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...properties}>
    <path d="M18 6.41 16.59 5 12 9.58 7.41 5 6 6.41l6 6 6-6Z" fill="currentColor" />
    <path d="m18 13-1.41-1.41L12 16.17l-4.59-4.58L6 13l6 6 6-6Z" fill="currentColor" />
  </svg>
);

export default SvgKeyboardDoubleArrowDown;
