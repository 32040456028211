import { SVGProps } from 'react';

const SvgMoreHoriz = (properties: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={4} fill="none" xmlns="http://www.w3.org/2000/svg" {...properties}>
    <path
      d="M2 0C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2Zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2ZM8 0C6.9 0 6 .9 6 2s.9 2 2 2 2-.9 2-2-.9-2-2-2Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgMoreHoriz;
