import { SVGProps } from 'react';

const SvgWarningOutlined = (properties: SVGProps<SVGSVGElement>) => (
  <svg width={22} height={19} fill="none" xmlns="http://www.w3.org/2000/svg" {...properties}>
    <path d="M11 3.99 18.53 17H3.47L11 3.99ZM11 0 0 19h22L11 0Z" fill="#A06B3E" />
    <path d="M12 14h-2v2h2v-2ZM12 8h-2v5h2V8Z" fill="#A06B3E" />
  </svg>
);

export default SvgWarningOutlined;
