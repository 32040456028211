import { ReactNode, useMemo, createContext, useContext } from "react";
import { useLocalStorage } from "../../helpers/useLocalStorage";

export interface IEnvSwitcherContext {
  env: string;
  setEnv: (env: string) => void;
}

export const EnvSwitcherContext = createContext<IEnvSwitcherContext>({
  env: "dev",
  setEnv: () => {},
});

export function EnvSwitcherProvider({ children }: { children: ReactNode }) {
  const [env, setEnv] = useLocalStorage("environment", "dev");

  const contextValue = useMemo(
    (): IEnvSwitcherContext => ({
      env,
      setEnv,
    }),
    [env, setEnv]
  );

  return (
    <EnvSwitcherContext.Provider value={contextValue}>
      {children}
    </EnvSwitcherContext.Provider>
  );
}

export const useEnvSwitcher = () => useContext(EnvSwitcherContext);
