import { SVGProps } from 'react';

const SvgHourglassEmpty = (properties: SVGProps<SVGSVGElement>) => (
  <svg width={12} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...properties}>
    <path
      d="M0 0v6h.01L0 6.01 4 10l-4 4 .01.01H0V20h12v-5.99h-.01L12 14l-4-4 4-3.99-.01-.01H12V0H0Zm10 14.5V18H2v-3.5l4-4 4 4Zm-4-5-4-4V2h8v3.5l-4 4Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgHourglassEmpty;
