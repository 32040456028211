import ReactDOM from "react-dom";
import App from "./App";
import "./styles/index.scss";
import { BrowserRouter } from "react-router-dom";
import { combineProviders } from "react-combine-providers";
import { QueryClient, QueryClientProvider } from "react-query";
import { EnvSwitcherProvider } from "./contexts";

const provider = combineProviders();
provider.push(BrowserRouter);
provider.push(EnvSwitcherProvider);

const MasterProvider = provider.master();

const queryClient = new QueryClient();

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <MasterProvider>
      <App />
    </MasterProvider>
  </QueryClientProvider>,
  document.getElementById("root")
);
