import { SVGProps } from 'react';

const SvgApi = (properties: SVGProps<SVGSVGElement>) => (
  <svg width={22} height={22} fill="none" xmlns="http://www.w3.org/2000/svg" {...properties}>
    <path
      d="m13 11-2 2-2-2 2-2 2 2Zm-2-6 2.12 2.12 2.5-2.5L11 0 6.38 4.62l2.5 2.5L11 5Zm-6 6 2.12-2.12-2.5-2.5L0 11l4.62 4.62 2.5-2.5L5 11Zm12 0-2.12 2.12 2.5 2.5L22 11l-4.62-4.62-2.5 2.5L17 11Zm-6 6-2.12-2.12-2.5 2.5L11 22l4.62-4.62-2.5-2.5L11 17Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgApi;
