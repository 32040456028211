import { SVGProps } from 'react';

const SvgLinkVector = (properties: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...properties}>
    <path
      d="M15 4h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1 0 1.43-.98 2.63-2.31 2.98l1.46 1.46C18.88 12.61 20 10.95 20 9c0-2.76-2.24-5-5-5Zm-1 4h-2.19l2 2H14V8ZM0 1.27l3.11 3.11A4.991 4.991 0 0 0 0 9c0 2.76 2.24 5 5 5h4v-1.9H5c-1.71 0-3.1-1.39-3.1-3.1 0-1.59 1.21-2.9 2.76-3.07L6.73 8H6v2h2.73L11 12.27V14h1.73l4.01 4L18 16.74 1.27 0 0 1.27Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgLinkVector;
