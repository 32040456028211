import { SVGProps } from 'react';

const SvgFolderOpen = (properties: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...properties}>
    <path
      d="M18 2h-8L8 0H2C.9 0 .01.9.01 2L0 14c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2Zm0 12H2V4h16v10Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgFolderOpen;
