import styled from "styled-components";

interface StyledButtonProps {
  backgroundColor: string;
  color?: string;
  borderColor: string;
  hover: {
    backgroundColor: string;
    color: string;
  };
  small?: boolean;
  width?: string;
}

export const StyledButton = styled.button<StyledButtonProps>`
  border: ${(props) => props.borderColor};
  background: ${(props) => props.backgroundColor};
  cursor: pointer;
  color: ${(props) => props.color};
  height: 2.5rem;
  align-items: center;
  height: auto;
  justify-content: center;
  margin: 1rem 0;
  width: ${(props) => props.width ?? "12rem"};
  padding: 0.5rem;
  border-radius: 0.3rem;
  :hover {
    background: ${(props) => props.hover.backgroundColor};
    color: ${(props) => props.hover.color};
  }
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 0.7rem;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  :disabled {
    border: 2px solid #bdbdbd;
    background: #fff;
    cursor: not-allowed !important;
    color: #bdbdbd;
  }
`;
