import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface UserState {
  user: User;
  setUser: (user: User) => void;
  removeUser: () => void;
}

const initialUser: User = {
  username: '',
  email: '',
  isLoggedIn: false,
};

export const useUserStore = create<UserState>()(
  persist(
    (set) => ({
      user: initialUser,
      setUser: (user: User) => set({ user }),
      removeUser: () => set({ user: initialUser }),
    }),
    {
      name: 'user-info',
      getStorage: () => sessionStorage,
    }
  )
);
