import { Configuration } from '@azure/msal-browser';
import { PublicClientApplication } from '@azure/msal-browser';

import { useEnvSwitcher } from '../contexts';

export const useAzureAuthenticationConfig = () => {
  const { env } = useEnvSwitcher();

  const AzureActiveDirectoryAppClientId: string =
    window.__RUNTIME_CONFIG__?.[
      `REACT_APP_CLIENT_ID_${env}` as keyof typeof window.__RUNTIME_CONFIG__
    ] || '';
  const AdminApiId: any =
    window.__RUNTIME_CONFIG__?.[
      `REACT_APP_API_ADMIN_ID_${env}` as keyof typeof window.__RUNTIME_CONFIG__
    ] || '';

  const MSAL_CONFIG: Configuration = {
    auth: {
      authority: `https://login.microsoftonline.com/${window.__RUNTIME_CONFIG__?.REACT_APP_TENANT_ID}`,
      clientId: AzureActiveDirectoryAppClientId,
      redirectUri: `${window.location.origin}/login`,
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false,
    },
  };

  const instance = new PublicClientApplication(MSAL_CONFIG);

  const loginRequest = {
    scopes: [`${AdminApiId}/admin.read`, `${AdminApiId}/admin.write`],
  };

  return {
    instance,
    loginRequest,
  };
};
