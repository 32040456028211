import { SVGProps } from 'react';

const SvgDownArrow = (properties: SVGProps<SVGSVGElement>) => (
  <svg
    width={19}
    height={18}
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path d="m15.25 9-1.057-1.058L10 12.127V3H8.5v9.127L4.315 7.935 3.25 9l6 6 6-6Z" />
  </svg>
);

export default SvgDownArrow;
