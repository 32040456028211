import styled from "styled-components";
import { Button } from "../../atoms";

export const StyledSuccessMessageContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  height: 20%;
`;

export const CloseButton = styled(Button)`
  border: none;
  position: absolute;
  top: -50px;
  right: -50px;
`;
