import { SVGProps } from 'react';

const SvgFullscreenOn = (properties: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...properties}>
    <path
      fill="currentColor"
      d="M16 3h6v6h-2V5h-4V3zM2 3h6v2H4v4H2V3zm18 16v-4h2v6h-6v-2h4zM4 19h4v2H2v-6h2v4z"
    />
  </svg>
);

export default SvgFullscreenOn;
