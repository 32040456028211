import { SVGProps } from 'react';

const SvgMenuOpen = (properties: SVGProps<SVGSVGElement>) => (
  <svg width={18} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" {...properties}>
    <path
      d="M0 12h13v-2H0v2Zm0-5h10V5H0v2Zm0-7v2h13V0H0Zm18 9.59L14.42 6 18 2.41 16.59 1l-5 5 5 5L18 9.59Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgMenuOpen;
